import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import AGB_EN from "../../static/PDF/AGB_EN_V1.00.pdf"
import style from "./style.module.scss"

import Layout from "components/Layout/en"

export default function(props) {
  const header = {
    text: "MoonVision Terms & Conditions",
    description:
      "MoonVision Terms & Conditions. These rules govern your use of our service.",
  }
  return (
    <Layout header={header} location={props.location}>
      <main class="white-background">
        <Container>
          <Row className="py-4">
            <Col md="8" className="py-3">
              <h1>Terms & Conditions</h1>
            </Col>
            <Col md="8" className="py-3">
              <p>
                These rules govern your use of our service.
                <a
                  className={style.linkInsideParagraph}
                  rel="noopener noreferrer"
                  href={AGB_EN}
                  target="_blank"
                >
                  {" "}
                  Current Version
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}
